import React from 'react';

import 'normalize.css';
import './layout.css';
import 'react-image-lightbox/style.css';

import { Navbar } from './navbar';

const Layout = ({ children }) => {
  return (
    <div className="mainContainer">
      <Navbar />
      <main className="main">{children}</main>
      <footer className="footer">
        &#169; 2020 BRAMINSTAL. Wszelkie prawa zastrzeżone.
        <div>
          Icons made by{' '}
          <a href="https://www.flaticon.com/authors/freepik" title="Freepik">
            Freepik
          </a>{' '}
          from{' '}
          <a href="https://www.flaticon.com/" title="Flaticon">
            {' '}
            www.flaticon.com
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
