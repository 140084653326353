import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Link, useStaticQuery, graphql } from 'gatsby';
import MobileMenu from '../svgs/mobileMenu.svg';
import styles from './navbar.module.scss';

export const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSubMenuOpen, setIsSubmenuOpen] = useState(false);
  useEffect(() => {
    if (isMobileMenuOpen) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = 'scroll';
    }
  }, [isMobileMenuOpen])
  const data = useStaticQuery(graphql`
    {
      file(name: { eq: "logo" }) {
        childImageSharp {
          fixed {
            src
          }
        }
      }
    }
  `);
  return (
    <main className={styles.container}>
      <img src={data.file.childImageSharp.fixed.src} alt="logo" className={styles.logo} />
      <section className={styles.mobileNav} onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
        <MobileMenu />
      </section>
        <section className={cx(styles.mobileMenu, isMobileMenuOpen && styles.show)}>
          <ul>
            <li>
              <Link to="/">O firmie</Link>
            </li>
            <li>
              <Link to="/galeria">Galeria</Link>
            </li>
            <li onClick={() => setIsSubmenuOpen(!isSubMenuOpen)}>
              Oferta
              {isSubMenuOpen && (
                <ul className={styles.mobileSubmenu}>
                  <li>
                    <Link to="/szlabany-blokady-parkingowe">Szlabany, blokady parkingowe</Link>
                  </li>
                  <li>
                    <Link to="/bramy-ogrodzeniowe-przemyslowe-garazowe">Bramy ogrodzeniowe, przemysłowe, garażowe</Link>
                  </li>
                  <li>
                    <Link to="/napedy-do-bram">Napędy do bram</Link>
                  </li>
                  {/*<li><Link to="/drzwi-wewnetrzne-i-zewnetrzne">Drzwi wewnętrzne i zewnętrzne</Link></li>*/}
                  <li>
                    <Link to="/okna-pcv-i-alu">Okna PCV i ALU</Link>
                  </li>
                  <li>
                    <Link to="/rolety-zewnetrzne-markizy-moskitiery">Rolety zewnętrzne, markizy, moskitiery</Link>
                  </li>
                  <li>
                    <Link to="/piloty-i-sterowanie-radiowe">Piloty i sterowania radiowe</Link>
                  </li>
                  {/*<li>Balustrady nierdzewne i całoszklane</li>*/}
                </ul>
              )}
            </li>
            <li>
              <Link to="/serwis">Serwis</Link>
            </li>
            <li>
              <Link to="/kontakt">Kontakt</Link>
            </li>
          </ul>
        </section>
      <nav className={styles.navbar}>
        <ul className={styles.navLinks}>
          <li>
            <Link activeClassName={styles.active} to="/">
              O firmie
            </Link>
          </li>
          <li>
            Oferta
            <ul className={styles.offerList}>
              <li>
                <Link to="/szlabany-blokady-parkingowe">Szlabany, blokady parkingowe</Link>
              </li>
              <li>
                <Link to="/bramy-ogrodzeniowe-przemyslowe-garazowe">Bramy ogrodzeniowe, przemysłowe, garażowe</Link>
              </li>
              <li>
                <Link to="/napedy-do-bram">Napędy do bram</Link>
              </li>
              {/*<li><Link to="/drzwi-wewnetrzne-i-zewnetrzne">Drzwi wewnętrzne i zewnętrzne</Link></li>*/}
              <li>
                <Link to="/okna-pcv-i-alu">Okna PCV i ALU</Link>
              </li>
              <li>
                <Link to="/rolety-zewnetrzne-markizy-moskitiery">Rolety zewnętrzne, markizy, moskitiery</Link>
              </li>
              <li>
                <Link to="/piloty-i-sterowanie-radiowe">Piloty i sterowania radiowe</Link>
              </li>
              {/*<li>Balustrady nierdzewne i całoszklane</li>*/}
            </ul>
          </li>
          <li>
            <Link activeClassName={styles.active} to="/galeria">
              Galeria
            </Link>
          </li>
          <li>
            <Link activeClassName={styles.active} to="/serwis">
              Serwis
            </Link>
          </li>
          <li>
            <Link activeClassName={styles.active} to="/kontakt">
              Kontakt
            </Link>
          </li>
        </ul>
      </nav>
    </main>
  );
};
